<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="headline" v-if="view.id">
        {{ $t('price.edittitle') }}
      </v-card-title>
      <v-card-title class="headline" v-if="!view.id">
        {{ $t('price.addtitle') }}
      </v-card-title>
      <v-card-text>
        <v-form v-model="valid" lazy-validation>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="view.name"
                :label="$t('price.name')"
                required
                :rules="[(v) => !!v || $t('all.fieldnoempty')]"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="view.cost"
                :label="$t('price.cost')"
                type="number"
                required
                :rules="[(v) => !!v || $t('all.fieldnoempty')]"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-select
                v-bind:items="groups"
                v-model="groupSelected"
                :label="$t('price.group')"
                bottom
                item-text="name"
                item-value="id"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" v-if="btnDel" flat @click.native="del">
          {{ $t('all.delete') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn flat="flat" @click.native="$emit('close')">
          {{ $t('all.close') }}
        </v-btn>
        <v-btn color="primary" dark @click.native="save">
          {{ $t('all.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['title', 'view', 'groups', 'groupsel'],
  data() {
    return {
      dialog: true,
      valid: false,
      btnDel: false,
      groupSelected: this.groupsel,
    }
  },
  computed: {},
  mounted: function () {
    this.btnDel = this.view.id ? true : false
  },
  methods: {
    del: function () {
      this.$emit('delete', this.view)
    },
    save: function () {
      var t = this

      if (t.valid) {
        this.$http
          .post(this.$store.state.apiUrl + 'price/saveService/', {
            name: this.view.name,
            cost: this.view.cost,
            group: this.groupSelected,
            id: this.view.id,
          })
          .then(
            (response) => {
              console.log(response.body)
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: t.$t(response.body.msg),
              })
              if (!response.body.err) {
                this.$emit('close', 'reload')
              }
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style></style>
