<template>
  <div>
    <v-row>
      <v-col>
        <h3 v-show="page == 'service'">{{ $t('price.title') }}</h3>
        <h3 v-show="page == 'group'">{{ $t('price.grouptitle') }}</h3>
      </v-col>
      <v-col class="text-right">
        <v-btn v-show="page == 'service'" outlined small color="primary" @click="page = 'group'">{{
          $t('price.grouptitle')
        }}</v-btn>
        <v-btn v-show="page == 'group'" outlined small color="primary" @click="page = 'service'">{{
          $t('price.title')
        }}</v-btn>
      </v-col>
    </v-row>
    <br />
    <v-divider></v-divider> <br />
    <span v-show="page == 'service'">
      <v-card>
        <v-card-title>
          <v-select
            v-bind:items="groupData"
            v-model="groupSelected"
            :label="$t('price.group')"
            bottom
            item-text="name"
            item-value="id"
          ></v-select>

          <v-spacer></v-spacer>
          <v-btn color="success" @click="addService" small> <v-icon>fa-plus</v-icon> {{ $t('all.add') }} </v-btn>
        </v-card-title>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">{{ $t('price.name') }}</th>
              <th class="text-left">{{ $t('price.cost') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in serviceF" :key="item.id" style="cursor: pointer" @click="editService(item)">
              <td>{{ item.name }}</td>
              <td>{{ item.cost }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </span>
    <span v-show="page == 'group'">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="addGroup" small> <v-icon>fa-plus</v-icon> {{ $t('all.add') }} </v-btn>
        </v-card-title>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">{{ $t('price.groupname') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in groupData" :key="item.id" style="cursor: pointer" @click="editGroup(item)">
              <td>{{ item.name }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card>
    </span>

    <group_edit
      v-if="group_edit"
      v-bind:title="groupTitle"
      v-bind:view="groupView"
      v-on:close="groupClose"
      v-on:delete="deleteGroup"
    ></group_edit>
    <group_delete v-if="group_delete" v-bind:view="groupView" v-on:close="groupClose"></group_delete>

    <service_edit
      v-if="service_edit"
      v-bind:title="serviceTitle"
      v-bind:view="serviceView"
      v-bind:groups="groupData"
      v-bind:groupsel="groupSelected"
      v-on:close="serviceClose"
      v-on:delete="deleteService"
    ></service_edit>
    <service_delete v-if="service_delete" v-bind:view="serviceView" v-on:close="serviceClose"></service_delete>
  </div>
</template>

<script>
import group_edit from './group_edit.vue'
import group_delete from './group_delete.vue'
import service_edit from './service_edit.vue'
import service_delete from './service_delete.vue'

export default {
  data() {
    return {
      page: 'service',
      tableLoad: false,
      groupData: ['load'],
      group_edit: '',
      group_delete: '',
      groupTitle: '',
      groupView: '',
      groupSelected: '',
      serviceData: ['load'],
      service_edit: '',
      service_delete: '',
      serviceTitle: '',
      serviceView: '',
      search: '',
    }
  },
  computed: {
    serviceF: function() {
      var t = this
      console.log(t.groupSelected)
      console.log(t.serviceData)
      return this.serviceData.filter(function(row) {
        return row.group === t.groupSelected
      })
    },
  },
  components: {
    group_edit,
    group_delete,
    service_edit,
    service_delete,
  },
  mounted: function() {
    this.serviceLoad()
    this.groupLoad()
  },
  methods: {
    serviceClose: function(prm) {
      this.service_edit = ''
      this.service_delete = ''
      if (prm == 'reload') {
        this.serviceLoad()
        this.$store.dispatch('updateService')
      }
    },
    groupClose: function(prm) {
      this.group_edit = ''
      this.group_delete = ''
      if (prm == 'reload') {
        this.groupLoad()
        this.$store.dispatch('updateService')
      }
    },
    serviceLoad: function() {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'price/service').then(
        response => {
          if (response.body.err > 0) {
            t.serviceData = ['error']
          } else {
            t.serviceData = response.body
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    groupLoad: function() {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'price/group').then(
        response => {
          if (response.body.err > 0) {
            t.groupData = ['error']
          } else {
            t.groupData = response.body
            if (!t.groupSelected) {
              t.groupSelected = t.groupData[0].id
            }
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    addService: function() {
      this.service_edit = true
      this.serviceView = []
    },
    addGroup: function() {
      this.group_edit = true
      this.groupView = []
    },
    editService: function(view) {
      this.service_edit = true
      this.serviceTitle = 'Редактирование услуги'
      this.serviceView = view
    },
    editGroup: function(view) {
      this.group_edit = true
      this.groupTitle = 'Редактирование группы услуги'
      this.groupView = view
    },
    deleteService: function(view) {
      this.service_delete = true
      this.serviceView = view
    },
    deleteGroup: function(view) {
      this.group_delete = true
      this.groupView = view
    },
  },
}
</script>

<style></style>
