<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title class="headline"> {{ $t('price.deltitle') }} </v-card-title>
      <v-card-text>
        <v-alert outlined type="warning" prominent border="left" color="error">
          <i18n path="price.delconfirm">
            <template v-slot:name>
              <b>{{ view.name }}</b>
            </template>
          </i18n>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat="flat" @click.native="$emit('close')">
          {{ $t('all.cancel') }}
        </v-btn>
        <v-btn color="error" dark @click.native="save">
          {{ $t('all.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['view'],
  data() {
    return {
      dialog: true,
    }
  },
  computed: {},
  mounted: function () {
    //        console.log(this.view)
  },
  methods: {
    save: function () {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'price/deleteService/', {
          id: this.view.id,
        })
        .then(
          (response) => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: t.$t(response.body.msg),
            })
            if (!response.body.err) {
              this.$emit('close', 'reload')
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
  },
}
</script>

<style></style>
